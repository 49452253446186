import {
    Dialog,
    DialogPanel,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
} from "@headlessui/react";
import { ChevronDownIcon, CogIcon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";

import NavDropdown from "./navDropdown";
import { Link, usePage, router } from "@inertiajs/react";
import Avatar from "../common/avatars/avatar";
import { Trello, Layers, LogOut } from "react-feather";
import { FormattedMessage } from "react-intl";
import { useEffect, useState } from "react";
import MobileNavTopItem, { ConditionalLink } from "./mobileNavTopItem";

export interface NavPageInterface {
    children: NavPageInterface[];
    depth: number;
    page: {
        entry_id?: string;
        id: string;
        permalink?: string;
        uri?: string;
        url?: string;
        hide_on_desktop?: boolean;
        icon?: string;
        inertia?: boolean;
        section?: { value: string; label: string; key: string };
        title: string;
        description?: string;
        feature_flags: string[];
    };
}

export default function Header() {
    const { auth, permissions, csrfToken, nav } = usePage().props;
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    // @ts-ignore
    useEffect(() => {
        // @ts-ignore
        Recite.load({
            enableButton: ".accessibility",
        });
    }, []);

    const shownItems = nav.map((item) => {
        return({...item,children: item.children.filter(
            (item) =>
                !item.page.feature_flags?.length ||
                item.page.feature_flags.some((i) => auth?.user?.permissions?.includes(i))
        )})
    })
    return (
        <div className="sm:border-b-2 sm:border-gray-100 relative rotate-0 z-50">
            <div className="mx-auto max-w-7xl flex items-center justify-between py-6 md:justify-start md:space-x-10 px-6">
                <div className="flex justify-start mr-4 md:mr-8 xl:mr-14">
                    <Link href={route("home")}>
                        <span className="sr-only">Voice</span>
                        <img
                            className="h-6 sm:h-10 md:w-auto"
                            src="/images/voice-logo.svg"
                            alt="Voice logo"
                        />
                    </Link>
                </div>
                <div className="-my-2 -mr-2 md:hidden">
                    <button
                        type="button"
                        className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-900 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500"
                        aria-expanded="false"
                        onClick={() => setMobileMenuOpen((prev) => !prev)}
                    >
                        <span className="sr-only">
                            <FormattedMessage id="Open menu" />
                        </span>
                        <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                            />
                        </svg>
                    </button>
                </div>
                <nav className="hidden pt-2 md:space-x-8 xl:space-x-20 md:flex">
                    <PopoverGroup className="hidden md:flex md:gap-x-12">
                        {shownItems
                            .filter((item) => !item?.page?.hide_on_desktop)
                            .map((item) => (
                                <NavDropdown
                                    key={item.page.id}
                                    items={item.children}
                                    title={item.page.title}
                                />
                            ))}
                    </PopoverGroup>
                </nav>

                <div className="hidden space-x-8 pt-2 items-center justify-end md:flex md:flex-1 lg:w-0">
                    <button
                        type="button"
                        className="accessibility whitespace-nowrap text-base font-medium text-gray-900 border-b-2 border-b-white hover:border-b-2 hover:border-b-gray-900 hover:text-gray-900 transition"
                    >
                        <FormattedMessage id="Accessibility" />
                    </button>
                    {auth.user ? (
                        <Popover className="relative">
                            <PopoverButton className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 lg:rounded-md lg:p-2 lg:hover:bg-gray-50">
                                <Avatar user={auth.user} />
                                <span className="ml-3 hidden text-sm font-medium text-gray-700 lg:block">
                                    <span className="sr-only">
                                        <FormattedMessage id="Open user menu for" />
                                    </span>
                                    {auth.user.first_name} {auth.user.last_name}
                                </span>
                                <ChevronDownIcon className="h-5 w-5 text-gray-400" />
                            </PopoverButton>

                            <PopoverPanel className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                {permissions.view_dashboard && (
                                    <a
                                        href={route("dashboard")}
                                        className="flex items-center px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                                    >
                                        <Trello className="h-4 w-4 text-gray-700 mr-2" />
                                        <FormattedMessage id="Dashboard" />
                                    </a>
                                )}
                                <a
                                    href={route("my-voice")}
                                    className="flex items-center px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                                >
                                    <Layers className="h-4 w-4 text-gray-700 mr-2" />
                                    <FormattedMessage id="My Voice" />
                                </a>
                                <Link
                                    href={route("user-settings")}
                                    className="flex items-center px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 hover:text-gray-800"
                                >
                                    <CogIcon className="h-4 w-4 text-gray-700 mr-2" />
                                    <FormattedMessage id="Settings" />
                                </Link>
                                <button
                                    onClick={() =>
                                        fetch(route("logout"), {
                                            method: "POST",
                                            headers: {
                                                "X-Csrf-Token": csrfToken,
                                            },
                                        }).then(() =>
                                            router.visit(route("home"))
                                        )
                                    }
                                    className="flex items-center px-4 py-2 text-sm text-gray-900 hover:bg-gray-100 hover:text-gray-800 w-full"
                                >
                                    <LogOut className="h-4 w-4 text-gray-700 mr-2" />
                                    <FormattedMessage id="Logout" />
                                </button>
                            </PopoverPanel>
                        </Popover>
                    ) : (
                        <>
                            <a
                                href={route("login")}
                                className="whitespace-nowrap text-base font-medium text-gray-900 border-b-2 border-b-white hover:border-b-2 hover:border-b-gray-900 hover:text-gray-900 transition"
                            >
                                <FormattedMessage id="Log in" />
                            </a>
                            <a
                                href={route("register")}
                                className="-mt-1 ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-lg border border-transparent bg-gray-900 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700"
                            >
                                <FormattedMessage id="Get started" />
                            </a>
                        </>
                    )}
                </div>

                <Dialog
                    as="div"
                    className="md:hidden"
                    open={mobileMenuOpen}
                    onClose={setMobileMenuOpen}
                >
                    <DialogPanel className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden z-[999]">
                        <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                            <div className="px-5 pb-6 pt-5">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <a href={route("home")}>
                                            <span className="sr-only">
                                                <FormattedMessage id="Voice" />
                                            </span>
                                            <img
                                                className="h-6 w-auto"
                                                src="/images/voice-logo.svg"
                                                alt="Voice logo"
                                            />
                                        </a>
                                    </div>
                                    <div className="-mr-2">
                                        <button
                                            className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                                            onClick={() =>
                                                setMobileMenuOpen(false)
                                            }
                                        >
                                            <span className="sr-only">
                                                <FormattedMessage id="Close menu" />
                                            </span>
                                            <XMarkIcon className="h-6 w-6" />
                                        </button>
                                    </div>
                                </div>
                                <div className="mt-6">
                                    <nav className="grid gap-y-6">
                                        {shownItems
                                            .map(
                                                (item) => item?.children ?? item
                                            )
                                            .flat()
                                            .filter(
                                                (item) =>
                                                    item.page.section?.value ===
                                                    "top"
                                            )
                                            .map((item) => (
                                                <MobileNavTopItem
                                                    item={item}
                                                    key={item.page.id}
                                                />
                                            ))}
                                    </nav>
                                </div>
                            </div>
                            <div className="space-y-6 py-6 px-5">
                                <div className="grid grid-cols-2 gap-y-4 gap-x-6">
                                    {shownItems
                                        .map((item) => item?.children ?? item)
                                        .flat()
                                        .filter(
                                            (item) =>
                                                item.page.section?.value ===
                                                "bottom"
                                        )
                                        .map((item) => (
                                            <ConditionalLink
                                                inertia={item?.page?.inertia}
                                                href={item.page.url}
                                                key={item.page.url}
                                                className="text-base text-gray-900 hover:text-gray-700"
                                            >
                                                {item.page.title}
                                            </ConditionalLink>
                                        ))}
                                </div>
                                <div>
                                    {auth.user ? (
                                        <button
                                            onClick={() =>
                                                fetch(route("logout"), {
                                                    method: "POST",
                                                    headers: {
                                                        "X-Csrf-Token":
                                                            csrfToken,
                                                    },
                                                }).then(() =>
                                                    router.visit(route("home"))
                                                )
                                            }
                                            className="items-center px-4 py-2.5 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 active:bg-gray-900 disabled:opacity-25 transition ease-in-out duration-150 flex w-full justify-center"
                                        >
                                            <FormattedMessage id="Logout" />
                                        </button>
                                    ) : (
                                        <>
                                            <a
                                                href={route("register")}
                                                className="flex w-full items-center justify-center rounded-md border border-transparent bg-gray-900 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700"
                                            >
                                                <FormattedMessage id="Get started" />
                                            </a>
                                            <p className="mt-6 text-center text-base font-medium text-gray-500">
                                                <FormattedMessage id="Existing Voice Member?" />
                                                <a
                                                    href={route("login")}
                                                    className="text-red-600 hover:text-red-500"
                                                >
                                                    {" "}
                                                    <FormattedMessage id="Sign in" />
                                                </a>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </DialogPanel>
                </Dialog>
            </div>
        </div>
    );
}
